.banner-window {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 300px;
    height: 90%;
    padding: 55px 5px;
    display: flex;
    flex-direction: column;
    margin: auto;
    left: 0px;
    justify-content: center;
    z-index: 900;

    display: flex,
    div {
        z-index: 1000;
        width: 100% !important;
        padding: 10px;
        margin-bottom: 10px;
        background-color: rgba(255, 255, 255, 0.7);
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
    }
}

.BannerWindow {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 300px;
    height: 90%;
    padding: 55px 5px;
    display: flex;
    flex-direction: column;
    margin: auto;
    left: 0px;
    justify-content: center;
    z-index: 900;
    display: flex;

    div {
        z-index: 1000;
        width: 100% !important;
        padding: 10px;
        margin-bottom: 10px;
        background-color: rgba(255, 255, 255, 0.7);
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
    }

    @include b('Modal') {
        display: flex;
        justify-content: center;
        vertical-align: center;
        align-items: center;
        overflow-y: auto;
    }

    @include b('ModalInner') {
        width: 800px;
        margin: auto;
        left: 50%;
        top: 42%;
        background-color: #f3f6f4;
        padding: 30px;
        // transform: translate(50%, 50%);
    }

    @include b('Text') {
        text-align: center;
        font-size: 20px;
        line-height: 1;
        margin: 17px 7px;
    }

    @include b('Ul') {

        li {
            display: flex;
            align-items: flex-start;
            margin-top: 0.7rem;
        }
    }

    @include b('IconCircle') {
        // box-shadow: 0px 9px 32px 0px rgb(0 0 0 / 7%);
        display: flex;
        padding: 10px;
        border-radius: 50%;
        margin-bottom: 1.5rem;
        background-color: #FFF;
        color: #f5378e;
        font-size: 48px;
        text-align: center;
        line-height: 40px;
        font-weight: 300;
        transition: all 0.3s ease;
        margin-right: 24px;
        min-width: 48px;

        img {
            width: 48px;
        }

        @include m('FixedSize') {
            width: 50px;
            height: 50px;
        }
    }

    @include b('DivH') {
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: $cn_Grey;
        // color: #633991;
        margin-bottom: 0.6rem;

        p {
            display: block;
            color: #959094;
        }
    }
}