@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700|Roboto+Condensed:400,700&subset=cyrillic);
@-webkit-keyframes hintFadeIn {
  0% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes hintFadeIn {
  0% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.Toastify__toast-container {
  pointer-events: none; }

.Toastify__toast {
  min-height: 64px;
  margin-bottom: 8px;
  padding: 8px;
  border: 2px solid #2196F3;
  font-size: 14px;
  color: #424242;
  background: #FFF !important;
  align-items: center;
  pointer-events: initial; }
  .Toastify__toast::before {
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 16px;
    margin-right: 16px;
    margin-left: 8px; }
  .Toastify__toast--success {
    border: 2px solid #8BC34A; }
    .Toastify__toast--success::before {
      background-color: #8BC34A; }
  .Toastify__toast--warning {
    border: 2px solid #FFC107; }
    .Toastify__toast--warning::before {
      background-color: #FFC107; }
  .Toastify__toast--error {
    border: 2px solid #FF5722; }
    .Toastify__toast--error::before {
      background-color: #FF5722; }

/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: https://meyerweb.com
	HTML5 Doctor				:: https://html5doctor.com
	and the HTML5 Boilerplate	:: https://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects
*/
img,
object,
embed {
  max-width: 100%; }

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

a:link {
  color: inherit; }

a:visited {
  color: inherit; }

a:hover {
  color: inherit; }

a:active {
  color: inherit; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #bfbfbf;
  margin: 1em 0;
  padding: 0;
  flex: 0 0 auto; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

b, strong {
  font-weight: bold; }

.Button {
  position: relative; }
  .Button_Iconed {
    cursor: pointer;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 15px;
    padding: 2px 5px;
    position: relative;
    fill: #FFF;
    background: #2196F3;
    color: #FFF;
    display: flex;
    border-radius: 0.2em;
    margin: 0.2rem; }
    .Button_Iconed:hover {
      background: rgba(33, 150, 243, 0.9); }
  .Button_Small {
    background-color: #00BCD4; }
    .Button_Small:hover {
      background-color: rgba(0, 188, 212, 0.7); }
  .Button__Icon {
    height: 32px;
    width: 32px;
    pointer-events: none;
    padding: 2px;
    margin-right: -4px; }
    .Button__Icon_NoTitle {
      height: 32px;
      width: 32px;
      margin-right: 0; }
    .Button__Icon_Small {
      height: 28px;
      width: 28px;
      padding: 0; }
    .Button__Icon_Smaller {
      height: 20px;
      width: 20px;
      padding: 0; }
  .Button__Title {
    padding: 0 8px;
    line-height: 32px; }
    .Button__Title_Iconed {
      padding-right: 16px; }
  .Button_Default {
    cursor: pointer;
    height: 32px;
    align-self: center;
    background-color: #2196F3;
    color: #FFF;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: none;
    white-space: nowrap;
    padding: 0 8px;
    font-size: 14px;
    font-family: "Roboto Condensed", sans-serif;
    fill: #FFF;
    padding: 14px 62px;
    align-self: flex-end;
    border-bottom: 1px solid #bfbfbf;
    border-top-right-radius: 0.2em;
    border-top-left-radius: 0.2em; }
    .Button_Default:hover {
      background-color: rgba(33, 150, 243, 0.9);
      fill: #FFF; }
    .Button_Default .Button__Title {
      line-height: 6px; }
  .Button_Warning {
    background-color: #FFC107; }
    .Button_Warning:hover {
      background-color: rgba(255, 193, 7, 0.9); }
  .Button_Danger {
    background-color: #FF5722; }
    .Button_Danger:hover {
      background-color: rgba(255, 87, 34, 0.9); }
  .Button_Confirm {
    background-color: #8BC34A; }
    .Button_Confirm.Button_DisabledIconed, .Button_Confirm.Button_DisabledIconed:hover {
      background: #DDD;
      cursor: auto;
      fill: #2196F3; }
    .Button_Confirm:hover {
      background-color: rgba(139, 195, 74, 0.9); }
  .Button_Unimportant {
    background-color: #bfbfbf; }
    .Button_Unimportant:hover {
      background-color: rgba(191, 191, 191, 0.9); }
  .Button_Disabled {
    cursor: pointer;
    height: 32px;
    align-self: center;
    background-color: #2196F3;
    color: #FFF;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: none;
    white-space: nowrap;
    padding: 0 8px;
    font-size: 14px;
    font-family: "Roboto Condensed", sans-serif;
    fill: #FFF;
    cursor: default;
    background-color: transparent !important;
    color: #2196F3;
    padding: 18px 62px;
    border-top: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    border-left: 1px solid #bfbfbf;
    border-top-left-radius: 0.2em;
    border-top-right-radius: 0.2em; }
    .Button_Disabled:hover {
      background-color: rgba(33, 150, 243, 0.9);
      fill: #FFF; }
    .Button_Disabled:hover {
      background-color: transparent !important; }
  .Button_DisabledIconed {
    cursor: default;
    color: #2196F3;
    background-color: #fff;
    fill: #4d4d4d; }
    .Button_DisabledIconed:hover {
      background-color: transparent;
      fill: #2196F3; }
    .Button_DisabledIconed.Button_Small, .Button_DisabledIconed.Button_Small:hover {
      cursor: no-drop;
      fill: #2196F3;
      background-color: #fff; }
  .Button_NewCheck {
    cursor: pointer;
    background-color: #4d4d4d;
    fill: #fff; }
    .Button_NewCheck:hover {
      background-color: #4d4d4d;
      fill: #fff; }
  .Button_NewCheckDis {
    cursor: pointer;
    background-color: #fff;
    fill: #4d4d4d; }
    .Button_NewCheckDis:hover {
      background-color: #fff;
      fill: #4d4d4d; }
  .Button_WithSpinner {
    border-bottom: 1px solid #bfbfbf !important;
    padding: 14px 62px; }
    .Button_WithSpinner .Button__Spinner {
      display: inline-block; }
  .Button_disabled {
    border-bottom: 1px solid #bfbfbf !important;
    padding: 14px 62px;
    cursor: not-allowed; }
  .Button__Spinner {
    display: none;
    position: relative;
    line-height: 16px;
    margin-top: -12px;
    padding-left: 20px; }
    .Button__Spinner > img {
      width: 16px;
      position: absolute;
      left: 0; }

.ButtonDisabled {
  cursor: no-drop;
  color: #2196F3;
  background-color: transparent;
  fill: #2196F3;
  border: 2px solid #FF5722; }

.NewBtn {
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  padding: 4px;
  position: relative;
  color: #FFF; }

.BtnMobile {
  display: none; }

.SwitcherAdress_Buttons .Button_Disabled {
  padding: 18px 32px; }

.SwitcherAdress_Buttons .Button_Default {
  padding: 14px 32px; }

.banner-window {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 90%;
  padding: 55px 5px;
  display: flex;
  flex-direction: column;
  margin: auto;
  left: 0px;
  justify-content: center;
  z-index: 900;
  display: flex, div;
    display-z-index: 1000;
    display-width: 100% !important;
    display-padding: 10px;
    display-margin-bottom: 10px;
    display-background-color: rgba(255, 255, 255, 0.7);
    display--moz-user-select: none;
    display--khtml-user-select: none;
    display-user-select: none; }

.BannerWindow {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 90%;
  padding: 55px 5px;
  display: flex;
  flex-direction: column;
  margin: auto;
  left: 0px;
  justify-content: center;
  z-index: 900;
  display: flex; }
  .BannerWindow div {
    z-index: 1000;
    width: 100% !important;
    padding: 10px;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    -moz-user-select: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .BannerWindow__Modal {
    display: flex;
    justify-content: center;
    vertical-align: center;
    align-items: center;
    overflow-y: auto; }
  .BannerWindow__ModalInner {
    width: 800px;
    margin: auto;
    left: 50%;
    top: 42%;
    background-color: #f3f6f4;
    padding: 30px; }
  .BannerWindow__Text {
    text-align: center;
    font-size: 20px;
    line-height: 1;
    margin: 17px 7px; }
  .BannerWindow__Ul li {
    display: flex;
    align-items: flex-start;
    margin-top: 0.7rem; }
  .BannerWindow__IconCircle {
    display: flex;
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    background-color: #FFF;
    color: #f5378e;
    font-size: 48px;
    text-align: center;
    line-height: 40px;
    font-weight: 300;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-right: 24px;
    min-width: 48px; }
    .BannerWindow__IconCircle img {
      width: 48px; }
    .BannerWindow__IconCircle_FixedSize {
      width: 50px;
      height: 50px; }
  .BannerWindow__DivH {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: #555;
    margin-bottom: 0.6rem; }
    .BannerWindow__DivH p {
      display: block;
      color: #959094; }

.Icon {
  display: inline-block;
  height: 100%;
  width: 100%; }
  .Icon__Tooltip {
    font-family: "Roboto Condensed", sans-serif; }

.Input {
  outline: none;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: none;
  min-width: 48px;
  width: 100%;
  height: 36px;
  line-height: 34px;
  font-size: 13px;
  padding: 0px 8px; }
  .Input::-webkit-input-placeholder {
    font-style: italic;
    color: #555;
    text-transform: lowercase; }
  .Input::-moz-placeholder {
    font-style: italic;
    color: #555;
    text-transform: lowercase; }
  .Input::-ms-input-placeholder {
    font-style: italic;
    color: #555;
    text-transform: lowercase; }
  .Input::placeholder {
    font-style: italic;
    color: #555;
    text-transform: lowercase; }
  .Input::-ms-clear {
    display: none; }
  .Input::-webkit-clear-button {
    display: none; }
  .Input__Checkbox {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 8px; }
    .Input__Checkbox:hover .Input__CheckboxMarker {
      opacity: 0.9; }
  .Input__TextareaInput {
    outline: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: none;
    min-width: 48px;
    width: 100%;
    height: 36px;
    line-height: 34px;
    font-size: 13px;
    padding: 0px 8px;
    flex: 1 0 auto;
    resize: vertical;
    line-height: 18px;
    padding: 8px;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 200px; }
    .Input__TextareaInput::-webkit-input-placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__TextareaInput::-moz-placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__TextareaInput::-ms-input-placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__TextareaInput::placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__TextareaInput::-ms-clear {
      display: none; }
    .Input__TextareaInput::-webkit-clear-button {
      display: none; }
    .Input__TextareaInput:hover {
      overflow-y: auto;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -moz-overflow-scrolling: touch;
      -ms-overflow-scrolling: touch;
      -o-overflow-scrolling: touch;
      overflow-scrolling: touch;
      padding-right: 2px; }
    .Input__TextareaInput::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px; }
    .Input__TextareaInput::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #BDBDBD;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
    .Input__TextareaInput::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .Input__DivInput {
    outline: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: none;
    min-width: 48px;
    width: 100%;
    height: 36px;
    line-height: 34px;
    font-size: 13px;
    padding: 0px 8px;
    flex: 1 0 auto;
    line-height: 18px;
    padding: 8px;
    white-space: nowrap; }
    .Input__DivInput::-webkit-input-placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__DivInput::-moz-placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__DivInput::-ms-input-placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__DivInput::placeholder {
      font-style: italic;
      color: #555;
      text-transform: lowercase; }
    .Input__DivInput::-ms-clear {
      display: none; }
    .Input__DivInput::-webkit-clear-button {
      display: none; }
  .Input__CheckboxInput {
    height: 16px;
    width: 16px;
    border: 1px solid #2196F3;
    background-color: #FFF; }
  .Input__CheckboxHidden {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%; }
  .Input__CheckboxMarker {
    height: 8px;
    width: 8px;
    margin: 3px;
    background-color: #2196F3;
    visibility: hidden; }
    .Input__CheckboxMarker_Enabled {
      visibility: visible; }

.TextLink {
  color: #2196F3 !important;
  text-decoration: none;
  display: inline;
  align-self: center;
  text-transform: uppercase;
  margin: 0 0.4em; }
  .TextLink:hover {
    text-decoration: underline; }
  .TextLink_Disabled {
    text-decoration: none;
    color: #555 !important; }

.AccountBadge {
  display: flex;
  align-items: center;
  margin: 0 1em; }
  .AccountBadge__Name {
    margin-right: 16px;
    font-weight: bold; }

.ModalWindow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999; }
  .ModalWindow__Overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1; }
  .ModalWindow__WindowDynamicIndent {
    margin-top: 16px;
    flex: 0 1 64px;
    z-index: 2; }
  .ModalWindow__Window {
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.4);
    background-color: #fff;
    padding: 32px;
    margin-bottom: 16px; }

.ConfirmDialog__Message {
  margin-bottom: 16px;
  text-align: center;
  max-width: 420px; }

.ConfirmDialog__Buttons {
  display: flex;
  justify-content: center; }
  .ConfirmDialog__Buttons > *:not(:last-of-type) {
    margin-right: 16px; }

.LoginForm {
  max-width: 320px; }
  .LoginForm__Validation {
    margin-bottom: 8px;
    color: #FF5722;
    text-align: center; }
  .LoginForm__Logo {
    height: 48px;
    margin-top: -8px;
    fill: #2196F3; }
  .LoginForm__Title {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
    color: #555; }
  .LoginForm__TitleLogin {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
    color: #555;
    min-width: 250px; }
  .LoginForm__FieldsBox {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-duration: .5s;
            animation-duration: .5s; }
  .LoginForm__Input {
    width: 100%;
    margin-bottom: 16px; }
  .LoginForm__Submit {
    text-align: center; }
    .LoginForm__Submit > input {
      width: 120px; }
  .LoginForm__Extra {
    display: flex;
    justify-content: space-between; }
  .LoginForm__Splitter {
    margin: 16px 0px;
    border-bottom: 1px solid #2196F3; }
  .LoginForm__Info {
    text-align: center;
    margin-bottom: 16px;
    font-weight: bold; }

.FeedBack {
  min-width: 280px; }
  .FeedBack__Validation {
    margin-bottom: 8px;
    color: #FF5722;
    text-align: center; }
  .FeedBack__Logo {
    height: 48px;
    margin-top: -8px;
    fill: #2196F3; }
  .FeedBack__Title {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
    color: #555; }
  .FeedBack__FieldsBox {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-duration: .5s;
            animation-duration: .5s; }
  .FeedBack__Input {
    width: 100%;
    margin-bottom: 16px; }
  .FeedBack__Submit {
    text-align: center; }
    .FeedBack__Submit > input {
      width: 120px; }
  .FeedBack__Extra {
    display: flex;
    justify-content: space-between; }
  .FeedBack__Splitter {
    margin: 16px 0px;
    border-bottom: 1px solid #2196F3; }
  .FeedBack__Info {
    text-align: center;
    margin-bottom: 16px;
    font-weight: bold; }
  .FeedBack .Input__TextareaInput {
    height: 100px !important; }

.switch-btn {
  display: inline-block;
  width: 40px;
  height: 20px;
  border-radius: 19px;
  background: #bfbfbf;
  z-index: 0;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  position: relative;
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  margin: 0.6em 0; }

.switch-btn::after {
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 17px;
  background: #fff;
  top: 3px;
  left: 3px;
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  position: absolute;
  z-index: 1; }

.switch-on {
  background: #2196F3; }

.switch-on::after {
  left: 23px; }

.running-line {
  position: fixed;
  bottom: 0;
  left: 430px;
  width: calc(100% - 430px);
  z-index: 999;
  overflow: hidden;
  padding: 8px;
  background: #FFF;
  border-top: 1px solid #bfbfbf; }
  .running-line:hover .running-line__inner-wrapper {
    -webkit-animation-play-state: paused;
            animation-play-state: paused; }
  .running-line__inner-wrapper {
    display: table-cell;
    white-space: nowrap;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 100s;
            animation-duration: 100s;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    -webkit-animation-name: move;
            animation-name: move;
    cursor: default;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .running-line__item {
    display: inline-flex;
    flex-direction: column;
    margin-right: 16px; }
  .running-line__user-name {
    color: #2196F3; }
  .running-line__subtitle {
    color: #424242; }
  @media (max-width: 1024px) {
    .running-line {
      display: none; } }

@-webkit-keyframes move {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes move {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

.LeafletMap {
  flex-grow: 1; }
  .LeafletMap > * {
    height: 100%; }
  .LeafletMap__Map {
    opacity: 1; }
    .LeafletMap__Map_Transparent {
      opacity: 0.4 !important; }
  .LeafletMap__Polyline {
    stroke-dasharray: 2px 12px;
    stroke-linecap: round;
    stroke-width: 2px;
    fill: none; }
    .LeafletMap__Polyline_Path {
      stroke-dasharray: none; }
    .LeafletMap__Polyline_Track {
      cursor: -webkit-grab;
      cursor: grab;
      stroke-dasharray: none; }
    .LeafletMap__Polyline_Dimmed {
      display: none;
      pointer-events: none; }
  .LeafletMap__PolylineShadow {
    stroke-dasharray: 2px 12px;
    stroke-linecap: round;
    stroke-width: 3.4px;
    stroke: #424242;
    stroke-opacity: 0.9;
    fill: none; }
    .LeafletMap__PolylineShadow_Path {
      stroke-dasharray: none; }
    .LeafletMap__PolylineShadow_Track {
      stroke: #FFF;
      stroke-dasharray: none;
      stroke-width: 4.4px;
      cursor: -webkit-grab;
      cursor: grab; }
    .LeafletMap__PolylineShadow_Dimmed {
      display: none; }
  .LeafletMap__Route {
    stroke-linecap: round;
    stroke-width: 6px; }
  .LeafletMap__MapHeader {
    max-height: 50px;
    border-bottom: 1px solid #2196F3;
    background-color: #fff;
    padding: 1em;
    display: table-caption; }
    .LeafletMap__MapHeader h1 {
      font-size: 2em;
      line-height: 0.8em;
      text-align: center; }

.LeafletMapMarker {
  fill: #2196F3;
  width: 48px !important;
  height: 48px !important;
  top: -42px;
  left: -18px; }
  .LeafletMapMarker_Courier_1 {
    fill: cornflowerblue;
    color: cornflowerblue; }
  .LeafletMapMarker_Courier_2 {
    fill: firebrick;
    color: firebrick; }
  .LeafletMapMarker_Courier_3 {
    fill: seagreen;
    color: seagreen; }
  .LeafletMapMarker_Courier_4 {
    fill: violet;
    color: violet; }
  .LeafletMapMarker_Courier_5 {
    fill: tomato;
    color: tomato; }
  .LeafletMapMarker_Courier_6 {
    fill: orange;
    color: orange; }
  .LeafletMapMarker_Courier_7 {
    fill: indigo;
    color: indigo; }
  .LeafletMapMarker_Courier_8 {
    fill: chocolate;
    color: chocolate; }
  .LeafletMapMarker_Courier_9 {
    fill: gold;
    color: gold; }
  .LeafletMapMarker_Courier_0 {
    fill: maroon;
    color: maroon; }
  .LeafletMapMarker__VisitedStatus {
    border-radius: 9px;
    position: absolute;
    width: 14px;
    height: 14px;
    top: -1px;
    right: 2px;
    border: 1px solid #0b171c;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    display: none; }
    .LeafletMapMarker__VisitedStatus_InWork {
      display: block;
      background-color: #FFC107; }
    .LeafletMapMarker__VisitedStatus_Success {
      display: block;
      background-color: #8BC34A; }
    .LeafletMapMarker__VisitedStatus_Failed {
      display: block;
      background-color: #FF5722; }
  .LeafletMapMarker_Failed * {
    color: #FF5722 !important;
    fill: #FF5722; }
  .LeafletMapMarker_Highlighted * {
    color: #8BC34A !important;
    fill: #8BC34A; }
  .LeafletMapMarker__Position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 32px;
    font-size: 19px;
    font-weight: bold; }
  .LeafletMapMarker__NoPosition {
    position: absolute;
    left: 16px;
    top: 8px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #2196F3; }
  .LeafletMapMarker__Tooltip {
    border-radius: .5em;
    padding: 8px 21px;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    color: #424242;
    background-color: #fff;
    border: 2px solid #8BC34A;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1em;
    box-shadow: none; }
    .LeafletMapMarker__Tooltip ::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -6px;
      left: 50%;
      margin-left: -8px;
      border-top-color: #8BC34A;
      border-top-style: solid;
      border-top-width: 6px; }
  .LeafletMapMarker__ToolTip_Address {
    font-weight: 800; }

.MapContextMenu {
  z-index: 500;
  background: #FFF;
  border: 1px solid #DDD; }
  .MapContextMenu > div {
    padding: 0.3em 0.5em;
    cursor: default; }
    .MapContextMenu > div:not(:last-child) {
      border-bottom: 1px solid #DDD; }
    .MapContextMenu > div:hover {
      background-color: #DDD; }

.Controls {
  position: relative;
  width: 430px;
  border-right: 1px solid #bfbfbf;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 7px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 92%; }
  .Controls::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px; }
  .Controls::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #BDBDBD;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .Controls h2 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 16px; }
  .Controls h3 {
    text-transform: none;
    font-size: 17px;
    font-weight: bold;
    text-align: center; }
  .Controls__Wrapper {
    display: flex;
    position: relative;
    justify-content: space-around;
    margin-top: 1em; }
    .Controls__Wrapper_ExtraMargin {
      margin-top: 0.3em; }
  .Controls__inWrapper {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%; }
  .Controls__Switcher {
    position: relative;
    display: flex;
    align-self: center; }
  .Controls__item {
    color: #bfbfbf;
    cursor: pointer;
    -webkit-transition: 100ms;
    transition: 100ms;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear; }
  .Controls__Top-Switcher {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0.3em 0 1.6em 0;
    font-size: 14px;
    height: 40px; }
    .Controls__Top-Switcher .active {
      padding: 0.7em;
      font-size: 16px;
      font-weight: 600;
      color: #424242;
      border-bottom: 2px solid #2196F3;
      -webkit-transition: 100ms;
      transition: 100ms;
      -webkit-transition-timing-function: linear;
              transition-timing-function: linear; }
  .Controls__Second-Switcher {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0em 0 1em 0;
    font-size: 13px;
    height: 40px; }
    .Controls__Second-Switcher .active {
      padding: 0.7em;
      font-size: 15px;
      font-weight: 600;
      color: #424242;
      border-bottom: 2px solid #90CAF9;
      -webkit-transition: 100ms;
      transition: 100ms;
      -webkit-transition-timing-function: linear;
              transition-timing-function: linear; }
  .Controls__SwitcherAdress {
    position: relative;
    display: flex;
    /* border: 1px solid $cn_Blue;
    border-radius: 1em; */
    overflow: hidden; }
  .Controls__SwitcherInfo {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #424242;
    font-size: 14px;
    white-space: nowrap;
    padding: 0 0.5em;
    line-height: 1.4em;
    /* position: absolute;
    bottom: -$g_Gut - 8px;
    left: 0;
    right: 0; */
    text-align: left;
    margin: 0.6em 0;
    width: 100px; }
  .Controls__Calculating {
    border: 2px solid #2196F3;
    padding: 0 16px;
    height: 48px;
    line-height: 44px;
    font-size: 14px;
    color: #2196F3;
    font-weight: bold;
    margin-top: 1em;
    border-radius: 0.2em; }
  .Controls__Header {
    position: relative;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .Controls__Logo {
    position: relative;
    top: 3px;
    fill: #2196F3;
    width: 100px;
    height: 48px; }
  .Controls__Account {
    height: 24px; }
  .Controls__RemoveAll {
    /* position: absolute;
    right: 0; */ }
  .Controls__AboutService {
    margin-top: 1em; }
    .Controls__AboutService h3 {
      text-align: center;
      font-size: 1rem; }
    .Controls__AboutService ul {
      margin: 1em 0; }
    .Controls__AboutService p {
      text-indent: 10px; }
  .Controls__FAQ_Header {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1em; }
  .Controls__d-none {
    display: none; }
  .Controls__activeFAQ {
    display: block;
    padding: 0.1em 0; }
  .Controls__Offer {
    border: 1px solid #CFD8DC;
    background-color: #ECEFF1;
    border-radius: 0.4em;
    padding: 1em;
    margin: 1em;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Controls__Offer h3 {
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-align: center;
      margin-bottom: 0.5em; }
    .Controls__Offer ul li {
      margin-bottom: 0.2em;
      margin-left: 1em;
      list-style-type: disc; }
  .Controls__IframeQuest {
    min-height: 200px;
    overflow: hidden;
    height: 400px;
    width: 500px; }
  .Controls__inFrame {
    min-height: 500px; }
  .Controls__Copyright {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 0 16px;
    margin: 1em 0;
    max-height: 80px; }
    .Controls__Copyright svg {
      width: 60px;
      height: auto; }
    .Controls__Copyright .svg_ok {
      fill: #ee8208; }
    .Controls__Copyright .svg_vk {
      fill: #4a76a8; }
    .Controls__Copyright svg:hover {
      opacity: 0.8; }
  .Controls__Validation {
    margin-bottom: 8px;
    color: #FF5722;
    text-align: center; }
  .Controls__favoriteRoute {
    border: 1px solid #CFD8DC;
    background-color: #ECEFF1;
    border-radius: 0.4em;
    padding: 1em;
    margin: 1em 0; }
    .Controls__favoriteRoute .favoriteRouteStatus {
      cursor: pointer;
      font-weight: bold;
      margin-top: 0.5em;
      text-align: center; }

@-moz-document url-prefix() {
  .Controls {
    overflow-y: auto; }
    .Controls:hover {
      padding-right: 9px; } }

.ControlsManualEditor {
  display: flex;
  flex-direction: column; }
  .ControlsManualEditor__AddressList {
    overflow: hidden auto;
    padding-right: 12px;
    margin-right: -12px;
    width: calc(100% + 12px);
    min-height: 96px;
    margin-bottom: 16px; }
    .ControlsManualEditor__AddressList::-webkit-scrollbar {
      width: 8px; }
    .ControlsManualEditor__AddressList::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0); }
    .ControlsManualEditor__AddressList::-webkit-scrollbar-thumb {
      background-color: #2196F3; }
  .ControlsManualEditor__LimitMassage {
    overflow: hidden auto;
    padding-right: 12px;
    margin-right: -12px;
    width: calc(100% + 12px);
    text-align: center;
    margin: 0.5em 0; }
    .ControlsManualEditor__LimitMassage::-webkit-scrollbar {
      width: 8px; }
    .ControlsManualEditor__LimitMassage::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0); }
    .ControlsManualEditor__LimitMassage::-webkit-scrollbar-thumb {
      background-color: #2196F3; }
  .ControlsManualEditor__BtnLimitMassage {
    overflow: hidden auto;
    padding-right: 12px;
    margin-right: -12px;
    width: calc(100% + 12px);
    margin: 0.5em 0;
    margin-left: auto;
    margin-right: auto !important;
    max-width: 205px;
    padding-left: 10px; }
    .ControlsManualEditor__BtnLimitMassage::-webkit-scrollbar {
      width: 8px; }
    .ControlsManualEditor__BtnLimitMassage::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0); }
    .ControlsManualEditor__BtnLimitMassage::-webkit-scrollbar-thumb {
      background-color: #2196F3; }
    .ControlsManualEditor__BtnLimitMassage .Button__Title {
      padding-left: 20px; }

.ControlsAddressItem {
  position: relative; }
  .ControlsAddressItem:not(:last-of-type) {
    margin-bottom: 16px; }
  .ControlsAddressItem_Highlighted input {
    background-color: #DCEDC8;
    -webkit-transition: background-color 200ms linear;
    transition: background-color 200ms linear; }
  .ControlsAddressItem__Address {
    padding-left: 38px; }
    .ControlsAddressItem__Address > input {
      padding: 0 64px 0 5px; }
    .ControlsAddressItem__Address_Geocoding > input {
      background-color: rgba(255, 193, 7, 0.42); }
    .ControlsAddressItem__Address_Garage > input {
      padding: 0 5px 0 5px; }
    .ControlsAddressItem__Address_GeocodeFailed > input {
      background-color: rgba(255, 87, 34, 0.42); }
  .ControlsAddressItem__AddressList {
    width: 100%;
    margin-bottom: 16px;
    overflow-y: auto;
    overflow-x: hidden; }
  .ControlsAddressItem__PositionIcon {
    position: absolute;
    pointer-events: none;
    top: 2px;
    left: 4px;
    height: 30px;
    width: 30px;
    fill: #2196F3; }
    .ControlsAddressItem__PositionIcon_Geocoding {
      fill: #FFC107; }
    .ControlsAddressItem__PositionIcon_GeocodeFailed {
      fill: #FF5722; }
  .ControlsAddressItem__EditButton {
    position: absolute;
    cursor: pointer;
    top: 0;
    height: 36px;
    width: 36px;
    padding: 8px;
    fill: #bfbfbf; }
    .ControlsAddressItem__EditButton:hover {
      opacity: 0.5; }
    .ControlsAddressItem__EditButton_Remove {
      right: 0; }
    .ControlsAddressItem__EditButton_WorkTime {
      right: 32px; }
    .ControlsAddressItem__EditButton_WorkTimeSet {
      fill: #2196F3;
      stroke: #2196F3;
      stroke-width: 8px; }
  .ControlsAddressItem__WorkTimeLabel {
    display: block;
    margin: 4px 0;
    color: #2196F3;
    font-weight: bold; }
  .ControlsAddressItem__WorkTime {
    display: flex; }
    .ControlsAddressItem__WorkTime > * {
      margin-right: 8px; }
  .ControlsAddressItem__FieldError {
    text-align: right;
    color: #FF5722;
    padding-top: 0.5em; }

.ControlsTextEditor {
  min-height: 140px;
  display: flex;
  flex-direction: column; }
  .ControlsTextEditor__AdreeHint {
    margin: 0.3em 0 0 0.5em; }
  .ControlsTextEditor__Row {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    height: 16px;
    line-height: 16px; }
    .ControlsTextEditor__Row_NoCoords {
      color: #FF5722; }
  .ControlsTextEditor__Wrapper {
    display: flex;
    position: relative;
    justify-content: space-around;
    margin: 1em 0; }

.ControlsResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem; }
  .ControlsResults__Statistics {
    margin-bottom: 16px; }
  .ControlsResults__Descriprion {
    margin-bottom: 1em;
    text-align: center;
    font-style: italic; }
  .ControlsResults__Param:not(:last-of-type) {
    margin-right: 16px; }
  .ControlsResults__RouteControls {
    display: flex;
    flex-wrap: wrap; }
    .ControlsResults__RouteControls_TopMargin {
      margin-top: 10px; }
    .ControlsResults__RouteControls > .Button:not(:last-child) {
      margin-right: 5px; }
  .ControlsResults__NavigateButton .Button__Icon {
    padding: 5px; }
  .ControlsResults .Button_DisabledIconed {
    background: #DDD;
    cursor: pointer;
    fill: #2196F3; }

.ControlsPersonalArea {
  display: flex;
  flex-direction: column; }
  .ControlsPersonalArea .warning {
    margin: 1em 0;
    padding: 1em;
    text-align: center;
    border: 1px solid red;
    font-size: 16px; }
  .ControlsPersonalArea h3 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 0.3em; }
  .ControlsPersonalArea label {
    margin: 1em 0 0.3em 0; }
    .ControlsPersonalArea label span {
      display: inline-block;
      width: 80px; }
  .ControlsPersonalArea input {
    outline: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: none;
    min-width: 48px;
    height: 36px;
    line-height: 34px;
    font-size: 13px; }
  .ControlsPersonalArea__Wrapper {
    display: flex;
    position: relative;
    justify-content: space-around;
    margin-top: 1em; }
  .ControlsPersonalArea__WorkTimeLabel {
    display: block;
    margin: 4px 0;
    color: #2196F3;
    font-weight: bold; }
  .ControlsPersonalArea__WorkTime {
    display: flex; }
    .ControlsPersonalArea__WorkTime > * {
      margin-right: 8px; }
  .ControlsPersonalArea__RoutesWrapper {
    margin: 2em 0 0 0; }
  .ControlsPersonalArea__RoutesButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .ControlsPersonalArea__RouteButton {
    margin-left: 0.5em;
    font-size: larger;
    font-weight: bold; }
  .ControlsPersonalArea__EditButton {
    cursor: pointer;
    top: 0;
    height: 36px;
    width: 36px;
    padding: 8px;
    fill: #bfbfbf; }
    .ControlsPersonalArea__EditButton:hover {
      opacity: 0.5; }
    .ControlsPersonalArea__EditButton_Remove {
      right: 0; }

.Input__Options {
  list-style: none;
  margin: 1em 0 1em 0;
  border-top: 2px solid #2196F3;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000; }
  .Input__Options li {
    padding: 0.4em 0 0.4em 0.2em;
    cursor: pointer;
    background-color: white; }
  .Input__Options li:hover {
    background-color: #DDD; }

.Input__Options:before {
  content: '';
  display: block;
  position: absolute;
  border: 7px solid transparent;
  border-bottom: 7px solid #2196F3;
  top: 35px;
  left: 42%; }

.siteHeader {
  width: 100%;
  max-height: 50px;
  border-bottom: 1px solid #bfbfbf;
  background-color: #fff;
  padding: 0.3em;
  display: flex;
  justify-content: space-around;
  align-items: center; }
  .siteHeader h1 {
    font-size: 1.7em;
    line-height: 0.8em;
    text-align: center;
    width: 100%; }
  .siteHeader svg {
    max-width: 100px;
    fill: #4d4d4d; }
  .siteHeader .nav {
    float: right;
    width: 100%; }
  .siteHeader .nav ul {
    list-style: none;
    padding: 0px; }
  .siteHeader .nav ul.toggle {
    display: none;
    visibility: hidden;
    height: 0px;
    overflow: hidden;
    margin-bottom: 0; }
  .siteHeader .nav ul li {
    display: inline-block;
    margin: 0 5px; }
  .siteHeader .nav ul li:last-child {
    margin: 0 0 0 23px; }
  .siteHeader .nav ul li a {
    display: block;
    font-size: 14px;
    color: #222222;
    font-family: 'Fairymuffinroundpop';
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out; }
  .siteHeader .nav ul li a:hover {
    color: #49b5e7; }

.BtnClose {
  width: 100px;
  float: right;
  margin: 0 10px 10px 0; }

.FirstQuiz h3 {
  font-size: 1.3em; }

.FirstQuiz p {
  margin: 0.3em 0;
  font-size: 1.2em; }

.FirstQuiz label {
  margin: 0.3em 0;
  font-size: 1.1em; }

.FirstQuiz input[type='radio'] {
  margin-right: 0.2em; }

.FirstQuiz input.Input {
  margin: 0.5em 0; }

.FirstQuiz .textarea {
  width: 100%;
  min-height: 100px;
  padding: 0.5em;
  margin: 1em 0; }

.FirstQuiz .QuizBtnGroup {
  display: flex;
  justify-content: space-around; }

.FirstQuiz .radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px; }

.FirstQuiz .radio__text {
  position: relative;
  padding: 0 0 0 25px;
  cursor: pointer;
  margin: 0.4em 0; }

.FirstQuiz .radio__text:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #CDD1DA;
  border-radius: 50%;
  background: #FFF; }

.FirstQuiz .radio__text:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #2196F3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: .2s;
  transition: .2s; }

.FirstQuiz .radio input:checked + .radio__text:after {
  opacity: 1; }

.FirstQuiz .endWindowBtn {
  display: flex;
  justify-content: center;
  margin-top: 1em; }

@media screen and (max-device-width: 720px) {
  html, body, body #root {
    height: auto;
    overflow-y: scroll; }
  .siteHeader {
    height: auto;
    max-height: none;
    flex-wrap: wrap;
    align-content: flex-start;
    z-index: 10; }
    .siteHeader svg {
      max-height: 50px; }
    .siteHeader h1 {
      font-size: 1.5em;
      margin-bottom: 0.3em; }
  .Controls {
    width: 100%;
    border-right: none;
    transition: transform .2s ease-in-out,opacity .2s ease-in-out,visibility .2s ease-in-out,-webkit-transform .2s ease-in-out; }
  .MobileSwitch-off .Controls {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform .2s ease-in-out,opacity .2s ease-in-out,visibility .2s ease-in-out,-webkit-transform .2s ease-in-out;
    z-index: 1;
    visibility: hidden;
    pointer-events: none; }
  .MobileSwitch-off .LeafletMap {
    height: 100%;
    width: 100%;
    position: fixed; }
  .titelh1 {
    order: 1; }
  .Controls__SwitcherAdress {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  .Button_Default {
    padding: 14px 0;
    width: 100%; }
  .Button_Disabled {
    padding: 18px 0;
    width: 100%; }
  .BtnMobile {
    position: absolute;
    bottom: 9px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    height: 40px;
    margin: 0;
    padding: 0 16px;
    border-radius: 20px;
    background-color: #198cff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    flex-shrink: 0;
    pointer-events: auto;
    color: #fff;
    z-index: 450;
    width: 200px; }
  .ControlsResults__Statistics {
    display: flex;
    flex-wrap: wrap; }
  .ModalWindow__WindowDynamicIndent {
    margin-top: 5px;
    flex: 0 1; }
  .Toastify__toast-container {
    margin-bottom: 4em; }
  .Controls__Copyright {
    margin-bottom: 9em; }
  .ModalWindow__Window {
    overflow-y: scroll; }
  .QuizBtnGroup .Button_Default {
    padding: 14px 0;
    width: 40%;
    text-align: center; } }

@media screen and (max-device-width: 390px) {
  .Controls__inWrapper {
    flex-wrap: wrap; }
  .Controls__IframeQuest {
    min-height: 370px;
    overflow: hidden;
    overflow-y: scroll;
    width: 320px; }
  .Controls__inFrame {
    min-height: 500px; } }

html {
  overflow: hidden; }

html, body, #root {
  height: 100%; }

body {
  font: 13px "Open Sans", sans-serif;
  line-height: 16px;
  color: #424242; }

h1, h2, h3, h4 {
  font-family: "Roboto Condensed", sans-serif;
  color: #555; }

a {
  text-decoration: underline;
  color: #2196F3 !important; }
  a:hover {
    text-decoration: none; }

.App {
  height: 100%;
  display: flex;
  flex-wrap: wrap; }

.onetow {
  color: red; }

